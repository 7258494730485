<template>
  <v-container fluid>
    <TableViewComponent
      title="Table structures juridiques > Super structures juridiques"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter une super structure juridique"
      itemLabel="super structure juridique"
      itemsLabel="super structures juridiques"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer cette super structure juridique ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { SuperLegalStructureService } from "@/service/etablishment/super_legal_structure_service.js";
import { LegalStructureService } from "@/service/etablishment/legal_structure_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";

export default {
  name: "TableSuperLegalStructure",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le classe d'accès au service */
      service: null,
      serviceLegalStructure: null,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() {
      this.$router.push(routes.superLegalStructure.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.entities = [];

        // Récupère la liste des super structure juridique
        let entities = await this.service.getAll();
        // Récupère la liste des établissements
        let legalStructures = await this.serviceLegalStructure.getAll();


        entities.forEach((l) => {
          let entity = JSON.parse(JSON.stringify(l));

          // Récupère l'objet structure juridque pour la structure juridique principale
          if (l.mainLegalStructure) {
            let main = legalStructures.find((legal) => legal.id == entity.mainLegalStructure);
            entity.main = main;
          }

          // Récupère le nombre de structure juridique associés
          entity.nbLegalStructure = "aucunes structure juridique";
          let nb = entity.legalStructures.length;
          if (nb > 0) {
            if (nb > 1) {
              entity.nbLegalStructure = nb + " structures juridiques";
            } else if (nb == 1) {
              entity.nbLegalStructure = "1 structure juridique";
            }
          }

          entity.view = () => {
            this.$router.push(
              routes.superLegalStructure.view.root + "/" + entity.id
            );
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(
              routes.superLegalStructure.edit.root + "/" + entity.id
            );
          };

          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression de la super structure juridique : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Nom digital",
          align: "start",
          sortable: true,
          value: "digitalName",
        },
        {
          text: "Nom complet",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Structure juridique principale",
          align: "start",
          sortable: true,
          value: "main.digitalName",
        },
        {
          text: "Nombre de structure(s) juridique(s)",
          align: "start",
          sortable: true,
          value: "nbLegalStructure",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditJuridique];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditJuridique, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [ RolesApplicationEnum.ReadJuridique, ];
    },
  },
  mounted() {
    //on instancie les services
    this.service = new SuperLegalStructureService(
      this.$api.getSuperLegalStructureApi()
    );
    this.serviceLegalStructure = new LegalStructureService(this.$api.getLegalStructuresApi());

    this.load();
  },
};
</script>

<style>
</style>